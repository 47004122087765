<template>
  <section class="pad-t-0">
    <div class="bb-container">
      <no-ssr>
        <portal-target name="alerts_ownerlist"></portal-target>
      </no-ssr>

      <div class="back">
        <div class="back__back">
          <a @click.prevent="$router.back()">
            <div class="back__name">
              <img
                src="../../assets/img/ico/ico-arrow-back.svg"
                loading="lazy"
                alt="Volver"
                class="back__arrow"
              />
            </div>
          </a>
        </div>
      </div>

      <div class="g-edit" v-show="loaded">
        <div class="g-edit__header">
          <h1 class="g-edit__title">{{ $t("productPage.editProduct") }}</h1>
          <div class="g-edit__musthave">
            <must-have-toggle @done="onMustHaveToggled()"></must-have-toggle>
          </div>
        </div>
        <div class="g-edit__cols">
          <div class="g-edit__picture">
            <h3 class="g-edit__head header-4">{{ $t("productPage.productImage") }}</h3>

            <div class="add-free-picture">
              <div class="add-free-picture__items">
                <no-ssr>
                  <VueSlickCarousel
                    v-if="showSlider"
                    v-bind="settings"
                    ref="carousel"
                    :key="$route.fullPath"
                  >
                    <div
                      v-for="(image, index) in images"
                      @click="selectImage(image)"
                      :key="index"
                    >
                      <div
                        class="add-free-picture__item"
                        :class="{ selected: selected(image) }"
                      >
                        <div class="add-free-picture__overlay"></div>
                        <span
                          v-if="isFree"
                          class="add-free-picture__delete"
                          @click.prevent.stop="removeImage(image)"
                        >
                          <img src="../../assets/img/ico/ico-delete-circle.svg" alt="" />
                        </span>
                        <img
                          :src="image"
                          loading="lazy"
                          width="100%"
                          height="auto"
                          class="add-free-picture__img"
                        />
                      </div>
                    </div>
                    <template #prevArrow="arrowOption">
                      <div class="slick-arrow--back">
                        {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                        <img src="../../assets/img/ico/ico-arrow-slick-left.svg" alt="" />
                      </div>
                    </template>
                    <template #nextArrow="arrowOption">
                      <div class="slick-arrow--next">
                        {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                        <img src="../../assets/img/ico/ico-arrow-slick-left.svg" alt="" />
                      </div>
                    </template>
                  </VueSlickCarousel>
                </no-ssr>
              </div>
            </div>

            <no-ssr>
              <div v-if="product.isFree" class="g-edit__upload g-edit__upload--edit">
                <div
                  class="g-edit__upload-error"
                  v-if="this.images && this.images.length > 12"
                >
                  {{ $t("productPage.notMoreThan12") }} <br />
                  {{ $t("productPage.removeToUploadMore") }}
                </div>
                <div class="g-edit__upload-input">
                  <input
                    type="file"
                    @change="previewAddedImage($event)"
                    name="uploadfile"
                    id="img"
                    style="display: none"
                    accept="image/*"
                  />
                  <label class="button button--dark-outline button--block" for="img"
                    >{{ $t("productPage.uploadImage") }}</label
                  >
                  <div class="bb-form-error" v-if="errorMsg">
                    {{ this.errorMsg }}
                  </div>
                  <p v-else>{{ $t("generic.photoMaxSize") }}</p>
                </div>
              </div>
            </no-ssr>
          </div>
          <div class="g-edit__content">
            <must-have-toggle @done="onMustHaveToggled()"></must-have-toggle>

            <h3 class="g-edit__head header-4">{{ $t("productPage.productData") }}</h3>

            <div class="toast toast--aqua mar-b-1" v-if="!product.isFree">
              <div class="toast__ico">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12V16C11 16.2652 11.1054 16.5196 11.2929 16.7071C11.4804 16.8946 11.7348 17 12 17C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8946 16.5196 13 16.2652 13 16V12C13 11.7348 12.8946 11.4804 12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11ZM12.38 7.08C12.1365 6.97998 11.8635 6.97998 11.62 7.08C11.4973 7.12759 11.3851 7.19896 11.29 7.29C11.2017 7.3872 11.1306 7.49882 11.08 7.62C11.024 7.73868 10.9966 7.86882 11 8C10.9992 8.13161 11.0245 8.26207 11.0742 8.38391C11.124 8.50574 11.1973 8.61656 11.29 8.71C11.3872 8.79833 11.4988 8.86936 11.62 8.92C11.7715 8.98224 11.936 9.00632 12.099 8.99011C12.2619 8.97391 12.4184 8.91792 12.5547 8.82707C12.691 8.73622 12.8029 8.61328 12.8805 8.46907C12.9582 8.32486 12.9992 8.16378 13 8C12.9963 7.73523 12.8927 7.48163 12.71 7.29C12.6149 7.19896 12.5028 7.12759 12.38 7.08ZM12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z"
                    fill="#0C5460"
                  />
                </svg>
              </div>
              <div class="toast__content">
                {{ $t("productPage.productFromOurCatalogue") }}
              </div>
            </div>

            <div class="edit-data">
              <div class="edit-data__item">
                <div class="bb-form-item">
                  <div class="bb-form-label">{{ $t("generic.name") }}</div>
                  <span class="edit-data__output" v-if="!product.isFree">{{ name }}</span>
                  <input
                    v-else
                    v-model="name"
                    class="bb-form"
                    type="text"
                    maxlength="256"
                    name="name"
                    id="name"
                    required=""
                    @blur="nameBlur"
                  />
                  <div class="bb-form-error" v-show="nameHasError">
                    {{ $t("generic.field_is_required") }}
                  </div>
                </div>
              </div>
              <div class="edit-data__item">
                <div class="bb-form-item">
                  <div class="bb-form-label">Url</div>
                  <a
                    :href="url"
                    target="_blank"
                    class="edit-data__output"
                    v-if="!product.isFree"
                    >{{ url }}</a
                  >

                  <!-- <input
                    v-else
                    v-model="url"
                    class="bb-form"
                    type="text"
                    maxlength="256"
                    name="url"
                    id="url"
                    required=""
                    @blur="urlBlur"
                  /> -->

                  <div class="bb-form-group" v-else>
                    <input
                      v-model="url"
                      class="bb-form"
                      type="text"
                      maxlength="256"
                      name="url"
                      id="url"
                      required=""
                      @blur="urlBlur"
                    />
                    <a :href="url" target="_blank" class="bb-form-group__ico">
                      <img src="../../assets/img/ico/ico-external-link.svg" />
                    </a>
                  </div>

                  <div class="bb-form-error" v-show="urlHasError">
                    {{ $t("generic.field_is_required") }}
                  </div>
                </div>
              </div>
              <div class="edit-data__item">
                <div class="bb-form-item">
                  <div class="bb-form-label">{{ $t("generic.price") }}*</div>
                  <span class="edit-data__output" v-if="!product.isFree">{{
                    $utils.formatMoney(price, currency)
                  }}</span>
                  <input
                    v-else
                    v-model="price"
                    class="bb-form"
                    type="number"
                    maxlength="256"
                    name="price"
                    id="price"
                    required=""
                    @blur="priceBlur"
                  />
                  <div class="bb-form-error" v-show="priceHasError">
                    {{ $t("generic.field_is_required") }}
                  </div>
                </div>
              </div>
              <div class="edit-data__item">
                <div class="bb-form-item">
                  <div class="bb-form-label">{{ $t("generic.category") }}*</div>
                  <span class="edit-data__output" v-if="!product.isFree">{{
                    productCategoryName
                  }}</span>
                  <div v-else class="ml-filters__filter">
                    <div
                      class="bb-filter-item bb-filter-item--inline"
                      id="categories_filter"
                    >
                      <div class="bb-filter" v-click-outside="config">
                        <div
                          class="bb-filter__select"
                          @click="toggle"
                          :class="toggleCategories ? 'bb-filter__select--active' : ''"
                        >
                          {{ categoryLabel }}
                          <img
                            src="../../assets/img/ico/ico-chevron-up.svg"
                            loading="lazy"
                            alt=""
                            width="16px"
                            :class="
                              toggleCategories
                                ? 'bb-filter__chevron bb-filter__chevron--active'
                                : 'bb-filter__chevron '
                            "
                          />
                        </div>
                        <div class="bb-filter__items" v-show="toggleCategories">
                          <div
                            class="bb-filter__item bb-filter__item--option"
                            v-for="(category, index) in categories"
                            :key="index"
                            @click="applyCategory(category)"
                            :class="
                              category.id === selectedCategory
                                ? 'bb-filter__item--active'
                                : ''
                            "
                          >
                            <span v-text="$t('categories.' + category.translationKey)" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--<div class="edit-data__item" v-if="!product.isFree">
                <div class="bb-form-item">
                  <div class="bb-form-label">Opciones</div>
                  <span class="edit-data__output">{{ selectedOptionsString }}</span>
                </div>
              </div>
              </div>-->

              <ProductOptions v-if="productIsReady && product.options" :show-price="false" :show-quantity="false" :product="product"></ProductOptions>

              <div class="edit-data__item">
                <div class="bb-form-item">
                  <div class="bb-form-label">{{ $t("productPage.comments") }}</div>
                  <textarea
                    v-model="userComments"
                    class="bb-form-textarea"
                    type="text"
                    maxlength="256"
                    row="4"
                    name="userComments"
                    id="userComments"
                    required=""
                  />
                </div>
              </div>
            </div>

            <product-status
              ref="productStatus"
              @done="onStatusDone()"
              @removeBooking="$refs.removeBooking.show()"
            ></product-status>

            <div class="g-edit__cancel">
              <remove-booking
                ref="removeBooking"
                @done="onRemoveBooking()"
                @cancel="updateState()"
              ></remove-booking>
            </div>

            <div class="edit-descubre" v-if="product.status === REGALADO">
              <div v-if="!this.product.ownerFlaggedItemAsBought">
                <div v-if="knownPerson">
                  <span v-if="product.status === this.REGALADO">
                    {{ $t("productPage.doYouWantToKnowWhoBuyIt") }}
                    <a
                      class="edit-descubre__action"
                      @click="discover"
                      v-if="discoverState"
                    >
                      {{ $t("productPage.discover") }}
                    </a>
                    <a class="edit-descubre__action" @click="hide()" v-else> {{ $t("generic.hide") }} </a>
                  </span>

                  <span class="edit-descubre__output" v-if="!discoverState">
                    <strong>{{ $t("productPage.purchasedBy") }}</strong> {{ buyerName }}
                  </span>
                </div>
                <span v-else class="edit-descubre__output">
                  {{ $t("productPage.purchasedByNoData") }}
                </span>
              </div>
              <span v-else class="edit-descubre__output">
                {{ $t("productPage.purchasedByYou") }}
              </span>
            </div>

            <div class="edit-descubre" v-if="product.status === RESERVADO">
              <div>
                <span>
                  "{{ $t("productPage.doYouWantToKnowWhoReservedIt") }}
                  <a class="edit-descubre__action" @click="discover" v-if="discoverState">
                    {{ $t("productPage.discover") }}
                  </a>
                  <a class="edit-descubre__action" @click="hide()" v-else> {{ $t("generic.hide") }} </a>
                </span>

                <span class="edit-descubre__output" v-if="!discoverState">
                  <strong>{{ $t("productPage.reservedBy") }} </strong> {{ bookerNameText }}
                </span>
              </div>
            </div>

            <div class="g-edit__actions-column">
              <loading-button v-if="saving"></loading-button>
              <button
                v-else
                class="button button--primary button--block"
                @click.prevent="save"
                :disabled="disabled"
              >
                {{ $t("generic.save") }}
              </button>

              <a @click.prevent="navOwnerList()">
                <button class="button button--secondary button--block">{{ $t("generic.cancel") }}</button>
              </a>

              <edit-product-delete :listId="user.listId"></edit-product-delete>
            </div>
          </div>
        </div>
      </div>
    </div>
    <alert v-if="showAlertLocal" />
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import { mapGetters } from "vuex";
import EditProductDelete from "./components/EditProductDelete";
import MustHaveToggle from "./components/MustHaveToggle";
import RemoveBooking from "./components/RemoveBooking";
import ProductStatus from "./components/ProductStatus";
import LoadingButton from "../../components/LoadingButton";
import vClickOutside from "v-click-outside";
import NoSSR from "vue-no-ssr";
import OwnerListAlert from "./components/OwnerListAlert";
import ProductOptions from "../product/components/ProductOptions.vue";

import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import RemoveImage from "./Mixins/RemoveImage";
import SelectImage from "./Mixins/SelectImage";
import PreviewPhoto from "./Mixins/PreviewPhoto";
import CanGetCategories from "../../Mixins/CanGetCategories";

export default {
  name: "EditProductPage",
  metaInfo: {}, // SERGI: DO NOT REMOVE https://github.com/nuxt/vue-meta/issues/391#issuecomment-848823788
  components: {
    EditProductDelete,
    MustHaveToggle,
    RemoveBooking,
    LoadingButton,
    ProductStatus,
    "no-ssr": NoSSR,
    alert: OwnerListAlert,
    VueSlickCarousel,
    ProductOptions,
  },
  mixins: [
    RemoveImage,
    SelectImage,
    PreviewPhoto,
    CanGetCategories,
  ],
  data() {
    return {
      productIsReady: false,
      preview: false,
      discoverState: true,
      name: "",
      nameDirty: false,
      url: "",
      urlDirty: false,
      price: "",
      priceDirty: false,
      userComments: "",
      selectedCategory: null,
      state: "",
      saving: false,
      config: {
        handler: this.closeCategories,
        events: ["dblclick", "click"],
      },
      toggleCategories: false,
      loaded: false,
      currentIndex: 0,
      showSlider: false,
      errorMsg: null,
      productPhotos: [],
      productUploadedPhotos: [],
      imageUrl: "",
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    ...mapGetters({
      user: "user",
      product: "ownerListProduct",
      categories: "categories",
      showAlertKey: "showAlertKey",
      showAlert: "showAlert",
      selectedOptions: "selectedOptions",
      selectedVariant: "selectedVariantOwnerListProduct",
      currency: "loggedInUserCurrency",
    }),
    selectedOptionsString() {
      let selectedOptions = "";
      this.selectedOptions.forEach((selectedOption) => {
        if (selectedOptions !=="") {
          selectedOptions = selectedOptions + ", " + selectedOption.name + ' : ' + selectedOption.value
        } else {
          selectedOptions = selectedOption.name + ' : ' + selectedOption.value
        }

      })
      return selectedOptions;
    },
    isFree() {
      return this.product?.isFree;
    },
    settings() {
      if (this.product) {
        return this.product.isFree
          ? {
              draggable: false,
              centerMode: false,
              slidesToShow: 2, // cambiar a 2
              slidesToScroll: 2,
              rows: 3, // cambiar a 3
              arrows: true,
              infinite: false,
              slidesPerRow: 1,
              responsive: [
                {
                  breakpoint: 767,
                  settings: {
                    centerMode: true,
                    rows: 1,
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: true,
                    arrows: false,
                  },
                },
              ],
            }
          : {
              draggable: false,
              centerMode: false,
              arrows: true,
              infinite: true,
            };
      }
      return {};
    },
    defaultPhotoIndex() {
      let x = this.productPhotos.indexOf(this.imageUrl);
      if (x === -1) {
        return (
          this.productPhotos.length +
          this.productUploadedPhotos.indexOf(this.imageUrl) +
          1
        );
      }
      return x + 1;
    },
    images() {
      return this.$utils.removeDuplicates([
        ...this.productUploadedPhotos,
        ...this.productPhotos,
      ]);
    },
    disabled() {
      if (this.name !== "" && this.url !== "" && this.price !== "") {
        return false;
      }
      return true;
    },
    showAlertLocal() {
      return this.showAlertKey === this.key && this.showAlert;
    },
    key() {
      return "edit_product_page";
    },
    categoryLabel() {
      const category = this.categories.find((category) => category.id === this.selectedCategory);
      let translatedName = "";
      const categoryId = category?.id;
      if (categoryId) {
        translatedName = this.getTranslatedCategory(categoryId);
      }
      return translatedName || category?.name || "";
    },
    buyerName() {
      return this.product.purchaseInfo?.buyerName;
    },
    bookerNameText() {
      return this.product.reservation?.name + "(" + this.product.reservation?.email + ")";
    },
    knownPerson() {
      if (!this.product.purchaseInfo) return false;
      return (
        this.product.purchaseInfo?.buyerName !== "" ||
        this.product.purchaseInfo?.buyerName !== null
      );
    },
    nameHasError() {
      return this.name === "" && this.nameDirty;
    },
    priceHasError() {
      return this.price === "" && this.priceDirty;
    },
    urlHasError() {
      return this.url === "" && this.urlDirty;
    },
    productCategoryName() {
      return this.getTranslatedCategory(this.product.mainCategoryId) || this.product.mainCategory;
    },
  },
  watch: {
    selectedVariant: {
      immediate: true,
      async handler(selectedVariant) {
        this.updatePrice(selectedVariant)
        if (this.product.retailerName === "HelloBB") {
          if(element.substring(0, element.indexOf("?")) == selectedVariant?.imageUrl.substring(0, selectedVariant?.imageUrl.indexOf("?")))
          {
            this.$refs.carousel.goTo(index)
          }
        }
        else {
          this.images.forEach(
          (element, index) => {
            if(element.substring(0, element.indexOf("#")) == selectedVariant?.imageUrl)
            {
              this.$refs.carousel.goTo(index)
            }
          })
        }
      },
    },
  },
  methods: {
    updatePrice (selectedVariant) {
      if (selectedVariant) {
        this.price =  selectedVariant.price?.toString();
        return;
      }
      this.price = this.product.price?.toString();
    },
    previewAddedImage(event) {
      this.previewPhoto(event);
      this.$refs.carousel.goTo(0);
    },
    selected(image) {
      if (this.product?.isFree) return this.imageUrl === image;
      return false;
    },
    async onMustHaveToggled() {
      await this.getProduct();
    },
    toggle() {
      this.toggleCategories = !this.toggleCategories;
    },
    applyCategory(category) {
      this.selectedCategory = category.id;
      this.toggleCategories = false;
    },
    closeCategories() {
      this.toggleCategories = false;
    },
    nameBlur() {
      this.nameDirty = true;
    },
    urlBlur() {
      this.urlDirty = true;
    },
    priceBlur() {
      this.priceDirty = true;
    },
    discover() {
      this.discoverState = false;
    },
    hide() {
      this.discoverState = true;
    },
    async getProductFromServer() {
      await this.getProduct(true);
    },
    async getProduct(server = false) {
      let payload = {
        // En els productes de catàleg el productId no és l'id de producte si l'id del producte a la llista pq un mateix producte pot estar amb diferents variants/opcions però mateix id
        productId: this.$route.params.id,
      };

      if (this.$route.params.productType === "free") {
        payload.listId = this.user?.listId;
      }
      if (server) {
        payload.server = server;
      }
      if (this.$route.params.productType === "catalog") {
        await this.$store.dispatch("getListProduct", payload);
      }
      if (this.$route.params.productType === "free") {
        await this.$store.dispatch("getOwnerListFreeProduct", payload);
      }
    },
    async proceedGetProduct() {
      this.$store.commit("setOwnerListProduct", {});
      try {
        await this.getProduct();
      } catch (error) {
        console.log(error);
        if (error.response?.status === 404) {
          await this.$router.push({ name: "notfound" });
        }
        throw error;
      }
    },
    async updateVariant() {
      if (this.selectedVariant) {
        const body = {
          shopifyProductId: this.product.shopifyProductId,
          shopifyProductVariantId: this.selectedVariant.shopifyId,
          variantId: this.selectedVariant.id,
        };
        await this.$store.dispatch("updateProductVariant", { listProductId: this.product.listProductId, body } );
      }
    },
    async updateUserComments() {
      const body = {
        title: this.userComments,
      };
      await this.$store.dispatch("updateProductComment", { product: this.product, body } );
    },
    async updateFreeProductData() {
      const body = {
        name: this.name,
        info: this.userComments,
        price: parseFloat(this.price.replace(",", ".")),
        url: this.url,
        mainCategoryId: this.selectedCategory,
        productPhotos: this.productPhotos,
        productUploadedPhotos: this.productUploadedPhotos.map((photo) => photo.replace(/^data:image\/[a-zA-Z]+;base64,/, "")),
        defaultPhotoIndex: this.defaultPhotoIndex
      };
      await this.$store.dispatch("updateFreeProductData",{ listId: this.user?.listId, product: this.product, body })
    },
    async save() {
      this.saving = true;
      if (this.product.isFree) {
        await this.updateFreeProductData();
      } else {
        await this.updateVariant();
        await this.updateUserComments();
      }
      window.localStorage.setItem(
        "alertMessage",
        this.$t("generic.saveMessage")
      );
      //if(!this.$utils.isApp()) this.$router.push({ name: "owner-list" });
      //else { location.href = process.env.APP_URL + "mi-lista?source=app"; }
      //await this.getList();
      this.saving = false;
      await this.$router.push({ name: "owner-list" });
    },
    updateState() {
      this.state = this.states.find((state) => state.id === this.product.status);
      this.$refs.productStatus.updateState();
    },
    async onRemoveBooking() {
      this.$alert.success(this.$t("productPage.successCancelReservation"), this.key);
      await this.getProduct();
      this.updateState();
    },
    async onStatusDone() {
      const oldStatus = this.product.status;
      await this.getProduct();
      const newStatus = this.product.status;
      this.updateState();
      if (newStatus === oldStatus) return;
      if (oldStatus === this.PENDIENTE) {
        this.$alert.success(
          this.$t("productPage.successFromAvailableToGifted"),
          this.key
        );
      }
      if (oldStatus === this.REGALADO) {
        this.$alert.success(
          this.$t("productPage.successFromGiftedToAvailable"),
          this.key
        );
      }
      if (oldStatus === this.RESERVADO) {
        this.$alert.success(
          this.$t("productPage.successFromReservedToGifted"),
          this.key
        );
      }
    },
    navOwnerList() {
      //if(!this.$utils.isApp()) this.$router.push({ name: "owner-list" });
      //else { location.href = process.env.APP_URL + "mi-lista?source=app"; }
      this.$router.push({ name: "owner-list" });
    },
  },
  async serverPrefetch() {
    await this.getProductFromServer();
  },
  created() {
    this.PENDIENTE = 1;
    this.REGALADO = 2;
    this.RESERVADO = 3;
    this.states = [
      {
        id: this.PENDIENTE,
        name: this.$t("generic.available"),
      },
      {
        id: this.REGALADO,
        name: this.$t("generic.purchased"),
      },
      {
        id: this.RESERVADO,
        name: this.$t("generic.reserved"),
      },
    ];
  },
  async mounted() {
    if (!this.product) {
      return await this.proceedGetProduct()
    }
    const type = this.product.isFree ? "free" : "catalog";
    if (type === "free") {
      if (
          parseInt(this.product.id) !== parseInt(this.$route.params.id) ||
          type !== this.$route.params.productType
      ) {
        await this.proceedGetProduct()
      } else {
        if (!this.product.server) {
          await this.proceedGetProduct()
        }
      }
    } else {
      if (
          parseInt(this.product.listProductId) !== parseInt(this.$route.params.id) ||
          type !== this.$route.params.productType
      ) {
        await this.proceedGetProduct()
      } else {
        if (!this.product.server) {
          await this.proceedGetProduct()
        }
      }
    }
    if (!this.product.id) return;
    this.productIsReady = true

    this.imageUrl = this.$getImgUrl(
      this.product.photos?.find((photo) => photo.main)?.path
    );
    this.productPhotos = this.product.photos?.map((photo) => this.$getImgUrl(photo.path));
    if (this.images.length > 0) this.showSlider = true;

    this.name = this.product.name;
    this.url = this.product.isFree
      ? this.product.originalUrl
      : "https://www.hellobb.net/" +
        this.$formatUrlhelloBB(this.product.brand || "") +
        "/" +
        this.product.id;
    this.userComments = this.product.userComments;

    this.updatePrice(this.selectedVariant)

    this.loaded = true;

    this.updateState();

    await this.$store.dispatch("getCategories");
    this.selectedCategory = this.product.mainCategoryId;
  },
};
</script>
