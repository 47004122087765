export default {
  methods: {
      removeImage(image) {
          let removedImageIsTheSelected1 = false;
          let removedImageIsTheSelected2 = false;
          this.productPhotos = this.productPhotos.filter((img) => {
              const result = img !== image;
              if (!result && this.imageUrl === img) removedImageIsTheSelected1 = true;
              return result;
          });
          this.productUploadedPhotos = this.productUploadedPhotos.filter((img) => {
              const result = img !== image;
              if (!result && this.imageUrl === img) removedImageIsTheSelected2 = true;
              return result;
          });
          if (removedImageIsTheSelected1) this.imageUrl = this.productPhotos[0];
          if (removedImageIsTheSelected2)
              this.imageUrl = this.productUploadedPhotos[0];
          if (this.images.length < 1) {
              this.showSlider = false
          }
      },
  }
}