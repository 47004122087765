<template>
  <span
    class="button button--red button--ico"
    @click="showModal = true"
    v-if="product.status === RESERVADO"
  >
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.93996 8.4998L11.8066 5.6398C11.9322 5.51426 12.0027 5.344 12.0027 5.16646C12.0027 4.98893 11.9322 4.81867 11.8066 4.69313C11.6811 4.5676 11.5108 4.49707 11.3333 4.49707C11.1558 4.49707 10.9855 4.5676 10.86 4.69313L7.99996 7.5598L5.13996 4.69313C5.01442 4.5676 4.84416 4.49707 4.66663 4.49707C4.48909 4.49707 4.31883 4.5676 4.19329 4.69313C4.06776 4.81867 3.99723 4.98893 3.99723 5.16646C3.99723 5.344 4.06776 5.51426 4.19329 5.6398L7.05996 8.4998L4.19329 11.3598C4.13081 11.4218 4.08121 11.4955 4.04737 11.5767C4.01352 11.658 3.99609 11.7451 3.99609 11.8331C3.99609 11.9211 4.01352 12.0083 4.04737 12.0895C4.08121 12.1708 4.13081 12.2445 4.19329 12.3065C4.25527 12.3689 4.329 12.4185 4.41024 12.4524C4.49148 12.4862 4.57862 12.5037 4.66663 12.5037C4.75463 12.5037 4.84177 12.4862 4.92301 12.4524C5.00425 12.4185 5.07798 12.3689 5.13996 12.3065L7.99996 9.4398L10.86 12.3065C10.9219 12.3689 10.9957 12.4185 11.0769 12.4524C11.1581 12.4862 11.2453 12.5037 11.3333 12.5037C11.4213 12.5037 11.5084 12.4862 11.5897 12.4524C11.6709 12.4185 11.7446 12.3689 11.8066 12.3065C11.8691 12.2445 11.9187 12.1708 11.9526 12.0895C11.9864 12.0083 12.0038 11.9211 12.0038 11.8331C12.0038 11.7451 11.9864 11.658 11.9526 11.5767C11.9187 11.4955 11.8691 11.4218 11.8066 11.3598L8.93996 8.4998Z"
        fill="#DC3545"
      />
    </svg>
    {{ $t("productPage.cancelReservation") }}
    <modal-dialog
      :show="showModal"
      @close="cancel()"
      target-class="modal__card--mini"
    >
      <div class="card-wizard">
        <div class="card-wizard__title">
          <h3 class="header-3 w-70">
            {{ $t("productPage.cancelReservationConfirmation", { name: name }) }}
          </h3>
          <div class="card-wizard__subtitle">
            {{ $t("productPage.cancelReservationConfirmationEmailWarning") }}
          </div>
        </div>

        <div class="card-wizard__actions card-wizard__actions--mt-0">
          <loading-button v-if="loading"></loading-button>
          <button
            v-else
            class="button button--primary button--block"
            @click="remove()"
          >
            {{ $t("productPage.cancelReservationButton") }}
          </button>
          <button
            class="button button--secondary mar-t-fs-smaller button--block"
            @click="cancel()"
          >
            {{ $t("generic.cancel") }}
          </button>
        </div>
      </div>
    </modal-dialog>
  </span>
</template>

<script>
import { mapGetters } from "vuex";
import ModalDialog from "../../../components/ui/ModalDialog";
import LoadingButton from "../../../components/LoadingButton";

export default {
  name: "RemoveBooking",
  components: {
    ModalDialog,
    LoadingButton,
  },
  data() {
    return {
      showModal: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      list: "ownerList",
      product: "ownerListProduct",
    }),
    name() {
      return this.product.reservation?.name || "";
    },
  },
  methods: {
    show() {
      this.showModal = true;
    },
    async remove() {
      this.loading = true;
      if(!this.product.isFree) await this.$store.dispatch("updateProductStatus",{product: this.product, status: this.PENDIENTE})
        else await this.$store.dispatch("updateFreeProductStatus",{list: this.list, product: this.product, status: this.PENDIENTE})
      // eslint-disable-next-line no-undef
      mixpanel.track("Cancelar reserva", {
        "Object name": this.product.name,
        "Cancelled by": "Owner"
      });

      this.loading = false;
      this.showModal = false
      this.$emit("done");
    },
    cancel() {
      this.showModal = false
      this.$emit("cancel");
    }
  },
  created() {
    this.RESERVADO = 3;
    this.PENDIENTE = 1;
  },
};
</script>
