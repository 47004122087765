<template>
  <must-have-toggle-intern :product="product" :loading="loading" @markAsMustHave="markAsMustHave" @markAsnotMustHave="markAsnotMustHave()"></must-have-toggle-intern>
</template>

<script>
import { mapGetters } from "vuex";
import MustHaveToggleIntern from "./MustHaveToggleIntern";

export default {
  name: "MustHaveToggle",
  components: {
    "must-have-toggle-intern": MustHaveToggleIntern
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      product: "ownerListProduct",
      list: "ownerList",
    }),
  },
  methods: {
    async markAsnotMustHave() {
      this.loading = true;
      if (!this.product.isFree) await this.$store.dispatch("setProductMustHave",{product: this.product , musthave: false})
      else await this.$store.dispatch("setFreeProductMustHave",{list: this.list, product: this.product , musthave: "notmusthave"})
      this.$emit("done");
      this.loading = false;
    },
    async markAsMustHave() {
      this.loading = true;
      if (!this.product.isFree) await this.$store.dispatch("setProductMustHave",{product: this.product , musthave: true})
      else {
        await this.$store.dispatch("setFreeProductMustHave",{list: this.list, product: this.product , musthave: "musthave"})
      }
      this.$emit("done");
      this.loading = false;
    },
  }
};
</script>
