<template>
  <div class="edit-status">
    <h4 class="edit-status__title">{{ $t("generic.status") }}</h4>
    <div class="edit-status__items">
      <div v-for="(stateObj,index) in states" :key="index" class="edit-status__item">
        <input
          :id="'state_' + stateObj.name"
          :key="stateObj.id"
          v-model="state"
          :value="stateObj"
          type="radio"
          :disabled="product.status !== RESERVADO && stateObj.id === RESERVADO"
        />
        <label
          :for="'state_' + stateObj.name"
          class=""
          :disabled="product.status !== RESERVADO && stateObj.id === RESERVADO"
        >
          {{ stateObj.name }}
        </label>
      </div>
    </div>

    <modal-dialog
      :show="showModalRegaladoFromReservado"
      @close="cancelRegalado"
      target-class="modal__card--mini"
    >
      <div class="card-wizard">
        <div class="card-wizard__title">
          <h3 class="header-3 w-70">
            {{ $t("productPage.fromReservedToPurchased", { name: name }) }}
          </h3>
          <div class="card-wizard__subtitle">
            {{ $t("productPage.fromReservedToPurchasedWarning", { name: name }) }}
            <a
              class="link link--underline link--bold"
              @click.prevent="removeBooking"
              >{{ $t("productPage.fromReservedToPurchasedWarningButton") }}</a
            >
          </div>
        </div>
        <div class="card-wizard__actions card-wizard__actions--mt-0">
          <loading-button v-if="loading"></loading-button>
          <button
            v-else
            class="button button--primary button--block"
            @click="markAsRegalado()"
          >
            {{ $t("generic.setAsPurchased") }}
          </button>
          <button
            class="button button--secondary mar-t-fs-smaller button--block"
            @click="cancelRegalado"
          >
            {{ $t("generic.cancel") }}
          </button>
        </div>
      </div>
    </modal-dialog>

    <modal-dialog
      :show="showModalPendienteFromRegalado"
      @close="cancelPendiente()"
      target-class="modal__card--mini"
    >
      <div class="card-wizard">
        <div class="card-wizard__title">
          <h3 class="header-3 w-70">
            {{ $t("productPage.fromPurchasedToAvailable") }}
          </h3>
        </div>
        <div class="card-wizard__actions card-wizard__actions--mt-0">
          <loading-button v-if="loading"></loading-button>
          <button
            v-else
            class="button button--primary button--block"
            @click="markAsPendiente()"
          >
            {{ $t("productPage.fromPurchasedToAvailableButton") }}
          </button>
          <button
            class="button button--secondary mar-t-fs-smaller button--block"
            @click="cancelPendiente()"
          >
            {{ $t("generic.cancel") }}
          </button>
        </div>
      </div>
    </modal-dialog>

    <modal-dialog
      :show="showModalRegaladoFromPendiente"
      @close="cancelRegaladoFromPendiente()"
      target-class="modal__card--mini"
    >
      <div class="card-wizard">
        <div class="card-wizard__title">
          <h3 class="header-3 w-70">
            {{ $t("productPage.toPurchasedConfirmation") }}
          </h3>
        </div>
        <div class="card-wizard__actions card-wizard__actions--mt-0">
          <loading-button v-if="loading"></loading-button>
          <button
            v-else
            class="button button--primary button--block"
            @click="markAsRegalado(true)"
          >
            {{ $t("productPage.toPurchasedConfirmationButton") }}
          </button>
          <button
            class="button button--secondary mar-t-fs-smaller button--block"
            @click="cancelRegaladoFromPendiente()"
          >
            {{ $t("generic.cancel") }}
          </button>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalDialog from "../../../components/ui/ModalDialog";
import LoadingButton from "../../../components/LoadingButton";

export default {
  name: "ProductStatus",
  components: {
    ModalDialog,
    LoadingButton,
  },
  data() {
    return {
      state: {},
      showModalRegaladoFromReservado: false,
      showModalPendienteFromRegalado: false,
      showModalRegaladoFromPendiente: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      product: "ownerListProduct",
      list: "ownerList",
    }),
    name() {
      return this.product.reservation?.name || "";
    },
  },
  watch: {
    state(newState) {
      if (newState) {
        if (
          newState.id === this.REGALADO &&
          this.product.status === this.RESERVADO
        ) {
          this.showModalRegaladoFromReservado = true;
        }
        if (
          newState.id === this.PENDIENTE &&
          this.product.status === this.REGALADO
        ) {
          this.showModalPendienteFromRegalado = true;
        }
        if (
          newState.id === this.REGALADO &&
          this.product.status === this.PENDIENTE
        ) {
          this.showModalRegaladoFromPendiente = true;
        }
        if (
          newState.id === this.PENDIENTE &&
          this.product.status === this.RESERVADO
        ) {
          this.$emit("removeBooking");
        }
      }
    },
  },
  methods: {
    async getList() {
      return await this.$store.dispatch("getOwnerList");
    },
    cancelPendiente() {
      this.showModalPendienteFromRegalado = false;
      this.$emit("done");
    },
    cancelRegalado() {
      this.showModalRegaladoFromReservado = false;
      this.$emit("done");
    },
    cancelRegaladoFromPendiente() {
      this.showModalRegaladoFromPendiente = false;
      this.$emit("done");
    },
    updateState() {
      this.state = this.states.find(
        (state) => state.id === this.product.status
      );
    },
    removeBooking() {
      this.showModalRegaladoFromReservado = false;
      this.$sleep(() => {
        this.$emit("removeBooking");
      }, 100);
    },
    async markAsRegalado(regaladoFromPendiente = false) {
      this.loading = true;
      try {
        if(!this.product.isFree) await this.$store.dispatch("updateProductStatus",{product: this.product, status: this.REGALADO})
        else await this.$store.dispatch("updateFreeProductStatus",{list: this.list, product: this.product, status: this.REGALADO})
      } catch (error) {
        console.log(error);
        this.loading = false;
        return;
      }

      window.mixpanel.track("Marca regalat", {
        "Gift name": this.product.name + (!this.product.selectedVariantText ? "" : (" - " + this.product.selectedVariantText)),
        "Gift retailer": this.product.isFree ? this.product.retailerName : this.product.productRetailers?.[0]?.name,
        "Gift retailers": this.product.isFree ? "" : this.product.productRetailers?.map((retailer) => retailer.name),
        "Gift URL": this.product.isFree ? this.product.originalUrl : "https://www.hellobb.net/p/" + this.product.id,
        "Role": "propietari",
        //get minimum price
        "Gift amount": this.product.isFree ? this.product.price : this.product.productRetailers?.reduce((min, retailer) => (retailer.price < min ? retailer.price : min), this.product.productRetailers[0].price),
        //"Gift amount": this.product.price,
        "Gift added date": this.product.addedAt,
        "Product ID": !this.product.isFree ? this.product.id : 0,
      });

      this.loading = false;
      if (regaladoFromPendiente) this.showModalRegaladoFromPendiente = false;
      else this.showModalRegaladoFromReservado = false;
      this.$emit("done");
      await this.getList();
    },
    async markAsPendiente() {
      this.loading = true;
      if(!this.product.isFree) await this.$store.dispatch("updateProductStatus",{product: this.product, status: this.PENDIENTE})
      else await this.$store.dispatch("updateFreeProductStatus",{list: this.list, product: this.product, status: this.PENDIENTE})
      this.loading = false;
      this.showModalPendienteFromRegalado = false;
      this.$emit("done");
      await this.getList();
    },
  },
  created() {
    this.PENDIENTE = 1;
    this.REGALADO = 2;
    this.RESERVADO = 3;
    this.states = [
      {
        id: this.PENDIENTE,
        name: this.$t("generic.available"),
      },
      {
        id: this.RESERVADO,
        name: this.$t("generic.reserved"),
      },
      {
        id: this.REGALADO,
        name: this.$t("generic.purchased"),
      },
    ];
  },
  async mounted() {
    this.updateState();
  },
};
</script>
