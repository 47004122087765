<template>
  <span
    class="g-edit__musthave-item"
    v-if="!product.mustHave"
    @click="$emit('markAsMustHave')"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1603 4.60981C19.0986 3.54782 17.691 2.90214 16.1935 2.79019C14.696 2.67825 13.2081 3.10748 12.0003 3.99981C10.7279 3.05345 9.14427 2.62433 7.5682 2.79885C5.99212 2.97338 4.54072 3.73859 3.50625 4.9404C2.47178 6.14221 1.9311 7.69133 1.99308 9.27583C2.05506 10.8603 2.71509 12.3625 3.84028 13.4798L11.2903 20.9298C11.3832 21.0235 11.4938 21.0979 11.6157 21.1487C11.7376 21.1995 11.8683 21.2256 12.0003 21.2256C12.1323 21.2256 12.263 21.1995 12.3849 21.1487C12.5067 21.0979 12.6173 21.0235 12.7103 20.9298L20.1603 13.4798C20.7429 12.8975 21.2051 12.2062 21.5205 11.4452C21.8358 10.6842 21.9982 9.86854 21.9982 9.04481C21.9982 8.22108 21.8358 7.40542 21.5205 6.64445C21.2051 5.88347 20.7429 5.19209 20.1603 4.60981ZM18.7503 12.0698L12.0003 18.8098L5.25028 12.0698C4.65545 11.4725 4.25025 10.7129 4.0855 9.88615C3.92076 9.05945 4.00381 8.2025 4.32423 7.42281C4.64465 6.64312 5.18817 5.97541 5.88662 5.50344C6.58507 5.03147 7.40734 4.77625 8.25028 4.76981C9.37639 4.77257 10.4554 5.22215 11.2503 6.01981C11.3432 6.11354 11.4538 6.18794 11.5757 6.23871C11.6976 6.28947 11.8283 6.31561 11.9603 6.31561C12.0923 6.31561 12.223 6.28947 12.3449 6.23871C12.4667 6.18794 12.5773 6.11354 12.6703 6.01981C13.4886 5.3107 14.5458 4.93896 15.6278 4.97984C16.7099 5.02072 17.736 5.47116 18.4985 6.24C19.2609 7.00884 19.7029 8.03865 19.7348 9.121C19.7667 10.2034 19.3862 11.2574 18.6703 12.0698H18.7503Z"
        fill="#D7D7D7"
      />
    </svg>

    <span>{{ $t("productPage.setMustHave") }}</span>
  </span>
  <span class="g-edit__musthave-item" v-else @click="$emit('markAsnotMustHave')">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_681_53789)">
        <path
          d="M20.1991 4.80011C19.0991 3.70011 17.6991 3.10011 16.1991 3.00011C14.6991 2.90011 13.1991 3.30011 11.9991 4.20011C10.6991 3.30011 9.19911 2.90011 7.59911 3.00011C5.99911 3.20011 4.59911 4.00011 3.49911 5.20011C2.49911 6.40011 1.89911 7.90011 1.99911 9.50011C2.09911 11.1001 2.69911 12.6001 3.79911 13.7001L11.2991 21.1001C11.3991 21.2001 11.4991 21.3001 11.5991 21.3001C11.6991 21.4001 11.8991 21.4001 11.9991 21.4001C12.0991 21.4001 12.2991 21.4001 12.3991 21.3001C12.4991 21.2001 12.5991 21.2001 12.6991 21.1001L20.0991 13.7001C20.6991 13.1001 21.0991 12.4001 21.4991 11.7001C21.7991 10.9001 21.9991 10.1001 21.9991 9.30011C21.9991 8.50011 21.7991 7.70011 21.4991 6.90011C21.1991 6.10011 20.7991 5.40011 20.1991 4.80011Z"
          fill="#5D50C6"
        />
      </g>
      <defs>
        <clipPath id="clip0_681_53789">
          <rect
            width="20"
            height="18.5"
            fill="white"
            transform="translate(2 3)"
          />
        </clipPath>
      </defs>
    </svg>
    <span class="fw-bold">{{ $t("productPage.setMustHave") }}</span>
  </span>
</template>

<script>

export default {
  name: "MustHaveToggleIntern",
  props: {
    loading: {
      type: Boolean,
      default:false
    },
    product: {
      type: Object,
      required: true
    }
  },
};
</script>
