export default {
    methods: {
        previewPhoto(event) {
            this.preview = true;
            const input = event.target;
            this.errorMsg = null;

            input.files.forEach((file) => {
                const maxAllowedSize = 4 * 1024 * 1024;
                if (file.size > maxAllowedSize) {
                    this.errorMsg = "Tamaño máximo 4MB";
                    return;
                }
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.productUploadedPhotos.unshift(e.target.result);
                    this.imageUrl = this.productUploadedPhotos[0];
                };
                reader.readAsDataURL(file);
            });
            if (!this.errorMsg) this.showSlider = true
        },
    }
}